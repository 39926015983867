'use client';
import { useIsMobile } from '@/app/[lang]/(landing)/hooks/useIsMobile';
import { useEffect } from 'react';

export const HEADER_ID = 'header-navbar';

function Header({ children }: { children: React.ReactNode }) {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      return;
    }
    let lastScrollTop = 0;

    const handleScroll = () => {
      const navbar = document.querySelector('.navbar') as HTMLElement;
      if (navbar) {
        const scrollTop = window.scrollY;
        if (scrollTop > lastScrollTop) {
          navbar.style.transform = 'translateY(-100%)';
        } else {
          navbar.style.transform = 'translateY(0)';
        }
        lastScrollTop = scrollTop;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  return (
    <header
      id={HEADER_ID}
      className='navbar flex justify-center min-h-[60px] fixed top-0 left-0 right-0 z-10 backdrop-blur-2xl bg-surface-1 transition-transform duration-300 ease-in-out'
    >
      {children}
    </header>
  );
}

export default Header;
