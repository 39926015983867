export function pathWithLocale<Locale extends string, Path extends string>(
  path: Path,
  locale: Locale,
): string {
  const pathSegments = path.trim().split('/').filter(Boolean);

  if (pathSegments.length === 0) {
    return `/${locale}`;
  }

  return `/${locale}/${pathSegments.join('/')}`;
}
