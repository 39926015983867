'use client';

import React, { createContext, useContext, useState, useEffect } from 'react';

const MOBILE_BREAKPOINT = 768; // px

const MobileContext = createContext<boolean | null>(null);

export function MobileProvider({
  children,
  initialMobile,
}: { children: React.ReactNode; initialMobile: boolean }) {
  const [isMobile, setIsMobile] = useState(initialMobile);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT); // Adjust this breakpoint as needed
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>
  );
}

export function useIsMobile() {
  const context = useContext(MobileContext);
  if (context === null) {
    throw new Error('useIsMobile must be used within a MobileProvider');
  }
  return context;
}
