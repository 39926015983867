'use client';
import { InfoIcon, Code2Icon, Home, UsersRoundIcon } from 'lucide-react';
import { ReactNode } from 'react';

export interface Route {
  href: string;
  label: string;
  i18nKey: string;
  disabled: boolean;
  target: string;
  icon: ReactNode;
}

export const ROUTE_LIST = [
  {
    href: '/',
    label: 'Home',
    i18nKey: 'navbar.home',
    disabled: false,
    target: '_self',
    icon: <Home className='size-5' />,
  },
  {
    href: '/case-studies',
    label: 'Customers',
    i18nKey: 'navbar.customers',
    disabled: false,
    target: '_self',
    icon: <UsersRoundIcon className='size-5' />,
  },
  {
    href: '/about',
    label: 'Company',
    i18nKey: 'navbar.about',
    disabled: false,
    target: '_self',
    icon: <InfoIcon className='size-5' />,
  },
  {
    href: 'https://docs.spherepay.co',
    label: 'Developers',
    i18nKey: 'navbar.developers',
    disabled: false,
    target: '_blank',
    icon: <Code2Icon className='size-5' />,
  },
] as const satisfies Route[];
