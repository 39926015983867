'use client';
import React from 'react';

import { cn } from '@/common/utils/cn';

import { Language } from '@/i18n';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

const LANGUAGES_LIST = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es', native: 'Español' },
  { label: 'Portuguese', value: 'pt', native: 'Português' },
];

interface LanguageSelectorProps {
  lang: Language;
  className?: string;
}

export function LanguageSelector({ lang, className }: LanguageSelectorProps) {
  return (
    <div className={cn('space-y-1', className)}>
      {LANGUAGES_LIST.map((item) => (
        <RadioItem
          key={item.value}
          label={item.label}
          value={item.value}
          selectedValue={lang}
          nativeLabel={item.native}
        />
      ))}
    </div>
  );
}

interface RadioItemProps {
  label: string;
  value: string;
  selectedValue: string;
  nativeLabel?: string;
}

const RadioItem: React.FC<RadioItemProps> = ({
  label,
  value,
  selectedValue,
  nativeLabel,
}) => {
  const isSelected = selectedValue === value;
  const pathname = usePathname();
  const [, lang, ...pathRest] = pathname.trim().split('/');

  const href = `/${value}/${pathRest.join('/')}`;

  return (
    <Link
      href={href}
      className={`flex items-center gap-x-2 cursor-pointer w-full text-left px-2.5 py-3 rounded-md ${
        isSelected ? 'bg-background-dim' : 'hover:bg-background-dim/50'
      }`}
    >
      <span
        aria-hidden
        className={cn(
          'grow-0 relative shrink-0 size-[1.125rem] border-2 border-text-subtle rounded-full',
          isSelected &&
            'border-brand after:bg-brand after:content-[""] after:absolute after:size-[0.625rem] after:left-1/2 after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full',
        )}
      />

      <span className='text-[0.9375rem] font-medium text-gray-700'>
        {label}{' '}
        {nativeLabel && <span className='text-gray-500'>({nativeLabel})</span>}
      </span>
    </Link>
  );
};
