'use client';

import { pathWithLocale } from '@/common/utils/pathWithLocale';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { ROUTE_LIST } from './routes';

interface NavLinkProps {
  route: (typeof ROUTE_LIST)[number];
  index: number;
}

const NavLink: React.FC<NavLinkProps> = ({ route }) => {
  const { t } = useTranslation();
  const pathname = usePathname();
  const [, lang] = pathname.trim().split('/');
  const href =
    route.href.match(/^https?:\/\//) !== null
      ? route.href
      : pathWithLocale(route.href, lang);

  const isDisabled = route.disabled
    ? 'pointer-events-none opacity-25 cursor-default'
    : 'cursor-pointer';

  return (
    <Link
      href={href}
      target={route.target}
      className={`group relative text-sm font-medium text-variant text-nowrap ${isDisabled}`}
    >
      {t(route.i18nKey)}
      {!route.disabled && (
        <div className='underline relative h-0.5 w-full'>
          <div className='absolute right-0 left-auto top-0 h-0.5 w-0 group-hover:right-auto group-hover:left-0 group-hover:w-full bg-[#438EF7] transition-all duration-300' />
        </div>
      )}
    </Link>
  );
};

export const DesktopLinks: React.FC = () => {
  return (
    <nav className='flex flex-col items-center'>
      <ul className='relative flex flex-row gap-x-10'>
        {ROUTE_LIST.map(
          (route, index) =>
            route.label !== 'Home' && (
              <NavLink key={route.label} route={route} index={index} />
            ),
        )}
      </ul>
    </nav>
  );
};
